@import "../theme/index";

.team {
  &__header {
    margin: 0;
    min-height: 100px;
    padding: 0 20px;
    line-height: 100px;
    text-transform: uppercase;
    font-size: 28px;
    background-color: $green;
    position: relative;
    font-weight: normal;
    color: $darkGrey;
    @include respond-to(tablets) {
      padding: 0 50px;
      font-size: 48px;

    }

    &::before {
      content: ' ';
      background-color: $green;
      position: absolute;
      height: 100%;
      width: 100vw;
      right: 99%;
      top: 0;
    }

    &::after {
      content: ' ';
      background-color: $green;
      position: absolute;
      height: 100%;
      width: 100vw;
      left: 99%;
      top: 0;
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    @include respond-to(tablets) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    @include respond-to(tablets) {
      width: 50%;
    }
    @include respond-to(desktop) {
      width: 25%;
    }
    @include respond-to(largedesktop) {
      width: 16.66%;
    }
  }

  &__button {
    width: 100%;
    height: 400px;
    border: none;
    padding: 0 5px 30px;
    margin: 0;
    background-color: transparent;
    display: flex;
    align-items: flex-end;
    cursor: pointer;

    &-close {
      font-weight: 300;
      border: none;
      font-size: 20px;
      position: absolute;
      top: -20px;
      right: -15px;
      height: 28px;
      cursor: pointer;
    }
  }

  &__image {
    width: 100%;
    max-height: 280px;
  }

  &__title {
    line-height: 50px;
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    position: absolute;
    z-index: 1;
    margin: 0;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    bottom: 0;
  }

  &__separator {
    margin: 0;
    background-color: $lightGreen;
    height: 50px;
    position: relative;

    &::before {
      content: ' ';
      background-color: $lightGreen;
      position: absolute;
      height: 50px;
      width: 5000px;
      right: 100%;
    }

    &::after {
      content: ' ';
      background-color: $lightGreen;
      position: absolute;
      height: 50px;
      width: 5000px;
      left: 100%;
    }
  }

  &__name {
    font-size: 30px;
    font-weight: bold;
    color: $darkRed;
    margin: 0;
    @include respond-to(desktop) {
      font-size: 2vw;
    }
    @include respond-to(fullHD) {
      font-size: 36px;
    }
  }

  &__position {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    @include respond-to(desktop) {
      font-size: 1.5vw;
    }
    @include respond-to(fullHD) {
      font-size: 24px;
    }
  }

  &__description {
    margin-top: 20px;
    @include respond-to(largedesktop) {
      font-size: 1.3vw;
    }
    @include respond-to(fullHD) {
      font-size: 24px;
    }
  }

  &__information {
    position: absolute;
    top: 85%;
    z-index: 2;
    width: 100%;
    background-color: $grey;
    padding: 30px 25px;
    @include respond-to(tablets) {
      top: 0;
      height: calc(100% - 50px);
    }
    @include respond-to(desktop) {
      width: 200%;
    }
    @include respond-to(largedesktop) {
      width: 300%;
    }

    &--left {
      right: 100%
    }

    &--right {
      left: 100%
    }

    &-header {
      position: relative;
      display: flex;
      justify-content: space-between;
    }

    &-body {
      overflow: auto;
      height: 90%;
    }
  }

}
